.dot-white {
    border-color: #EEEEEE!important;
}

.dot-green {
    border-color: #4ECCA3!important;
}

.job-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1%;
    margin-bottom: 1%;
}

.skills {
    color: #4ECCA3;
}
.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    text-align: center;
}

@media (max-width: 600px){
  .intro {
    margin-top: 5%;
  }

  .rows {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .accent {
    font-size: 1rem;
  }
}

.accent {
    color: #4ECCA3;
}

/* Hide the text initially */
.hidden {
    opacity: 0;
}

.typewriter h2 {
    color: #4ECCA3;
    overflow: hidden; 
    border-right: .15em solid #4ECCA3; 
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation: 
      typing 3.5s steps(30, end),
      blink-caret .5s step-end infinite;
  }
  
  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #4ECCA3 }
  }
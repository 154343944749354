.card {
    background-color: #393E46!important;
    height: 100%;
    /*width: 30%;*/
}

.card:hover {
    transform: scale(1.02); /* Scale up to 120% on hover */
}

.github {
    color: #EEEEEE;
}

.grid {
    padding-left: 1%;
    padding-right: 1%;
}

.header-container {
    display: flex;
    justify-content: space-between;
}

.logo-image {
    max-width: 5%;
    height: auto;
    margin-left: 10px;
    object-fit: contain;
}

.modal-card {
    width: 80%;
    background-color: #393E46!important;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
}

.modal-content {
    max-height: 80vh; /* Set a maximum height for the modal content */
    overflow-y: auto; /* Enable vertical scrolling */
}

.p-height {
    min-height: 200px;
}

.project-image {
    max-width: 50%;
    height: auto;
    margin: auto;
    display: block;
}

.skills {
    color: #4ECCA3;
}

.icons:hover {
    transform: scale(1.2); /* Scale up to 120% on hover */
    color: #4ECCA3 /* Change the color on hover (adjust to your preferred color) */
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app-bar-center {
  margin: auto;
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.app-bar-btn {
  /* width: 300%; */
  justify-content: center;
}

.background {
  background-color: var(--black);
}

.button {
  text-decoration: none;
}

.container {
  padding: 15% 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.font {
  color: #EEEEEE;
  font-family: 'JetBrains Mono', monospace;
}

.MuiAppBar-root {
  background-color: transparent !important;
  outline: 0;
  border: 'none';
}

/* color palette */
/* https://colorhunt.co/palette/232931393e464ecca3eeeeee */
.palette {
  --black: #232931;
  --grey: #393E46;
  --teal: #4ECCA3;
  --white: #EEEEEE;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* color palette */
/* https://colorhunt.co/palette/232931393e464ecca3eeeeee */
.palette {
  --black: #232931;
  --grey: #393E46;
  --teal: #4ECCA3;
  --white: #EEEEEE;
}

body {
  background-color: #232931;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
